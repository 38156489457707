<template>
  <div>
    <div style="margin-top: 35px">
      <ResponsiveImage :image="mainImage"></ResponsiveImage>

      <br>
      <br>
      <p class="font-weight-900" style="font-size: 19px">tulum.party</p>

      <h2
        class="tulum-party-title mb-0"
        style="
          text-transform: uppercase;
          font-size: 1rem;
          z-index: 10;
          font-weight: bold;
        "
      >
        Parties, Nightlife & Techno Music
      </h2>


      <br>


      <h3
        class="tulum-party-subtitle"
        style="
                margin-top: 1px;
                text-transform: uppercase;
                font-size: 0.7rem;
                 margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
                max-width: 350px;
                letter-spacing: 0.3rem;
                font-weight: normal;
                color: mediumaquamarine !important;
              "
      >
        The best parties & events happening around Tulum
      </h3>


    </div>


  </div>
</template>
<script>
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
export default {
  name: 'HomeMainImage',
  components: { ResponsiveImage },
  data: () => {
    return {
      logo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      miniLogo: 'https://imgix.cosmicjs.com/57a1ab60-a461-11ee-b417-db331415685f-tpicon.png',
      marcianitos: 'https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png',
      mainImage:
        'https://imgix.cosmicjs.com/2bc0e300-0c00-11f0-8617-41c4c9731576-659656992763397.png',
      imgIdx: -1
    }
  },
}
</script>
