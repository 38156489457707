<template>
  <section style="width: 100%;" >

    <div
      class="text-center"
         :style="{ background: `linear-gradient( 45deg, ${primaryColor}, ${secondaryColor})` }">


      <div
        class="col-12"

      v-for="event in brands" :key="event.slug">
        <MiniBrandCard
          :title="event.title"
          :image="event.image"
          :text="event.text"
          :link="event.link"
        ></MiniBrandCard>

      </div>
      <small style="margin-top: -38px;padding: 20px">TOP PRODUCTIONS BRANDS KEEPING THE PARTY ALIVE</small>

    </div>
  </section>
</template>
<script>
import MiniBrandCard from '../../components/Cards/MiniBrandCard'

export default {
  name: 'HighLightCollection',
  props: ['desktop'],
  methods: {},
  data: () => {
    return {
      dj:      'https://imgix.cosmicjs.com/fae1e4a0-bede-11ef-bee4-3bb1d3c55332-624920291847749.png',

      primaryColor: '#1C1C1F',
      secondaryColor: '#262525',
      showIdx: 0,
      showAction: '',
    };
  },
  computed: {
    brands: {
      get() {
        return [
          {
            title: 'Afterlife',
            link: '/brands/afterlife-tulum',
            image: 'https://imgix.cosmicjs.com/050b5df0-2f68-11ef-adb1-8b946b3a80e4-logo-afterlife-tulum.png',
            text: 'An odyssey through the realm of consciousness.'
          },
          {
            title: 'DAY ZERO',
            link: '/brands/day-zero-tulum',
            image: 'https://imgix.cosmicjs.com/58671430-332d-11ef-a504-63e081e4680f-logo-day-zero-instagram.png',
            text: 'A new time to be reborn'
          },
          {
            title: 'Keinemusik',
            link: '/brands/keinemusik-tulum',
            image: 'https://imgix.cosmicjs.com/de2b3700-3e4e-11ef-a504-63e081e4680f-logo-keinemusik-brand.png',
            text: 'A networks core, like clouds that gather, forevermore.'
          },
          {
            title: 'Mayan Warrior',
            link: '/brands/mayan-warrior-tulum',
            image: 'https://imgix.cosmicjs.com/5650b710-38ae-11ef-a504-63e081e4680f-mayan-warrior-brand-logo-insta.png',
            text: 'Honoring tradition & igniting innovation through your inner warrior.'
          },
          {
            title: 'SET Underground',
            link: '/brands/set-underground-tulum',
            image: 'https://imgix.cosmicjs.com/6c9d8730-947d-11ee-8a8d-abf95e574482-image.png',
            text: 'Creating unforgettable experiences (Tulum, Oaxaca, SF, LA, Miami)'
          },
          {
            title: 'Zamna Tulum',
            link: '/brands/zamna-tulum',
            image: 'https://imgix.cosmicjs.com/ce9ecac0-947d-11ee-8a8d-abf95e574482-image.png',
            text: 'Not Only Electronic Music. Exclusive space of 11 acres at Tulum'
          },
          {
            title: 'Mystical Experiences',
            link: '/brands/mystical-sunset-tulum',
            image: 'https://imgix.cosmicjs.com/75e42950-99ed-11ee-bdf1-7fea048bb7c9-image.png',
            text: 'Feelings, Experiences & Music.'
          },
          {
            title: 'ANTS',
            link: '/brands/ants-tulum',
            text: 'A Colony of Beats. Diverse, Connected, Unforgettable.',
            image: 'https://imgix.cosmicjs.com/83ade300-38b2-11ef-a504-63e081e4680f-logo-ants-brand.png'
          }
,
          {
            title: 'Ancestral Soul',
            link: '/brands/ancestral-soul-tulum',
            image: 'https://imgix.cosmicjs.com/b5507900-9cf3-11ee-93ed-8bfbf1aced8f-image.png',
            text: 'Connecting you with nature and your ancestors. 🍃'
          },
          {
            title: 'Ten Ibiza',
            link: '/brands/ten-ibiza-tulum',
            image: 'https://imgix.cosmicjs.com/cd45e480-99ee-11ee-bdf1-7fea048bb7c9-image.png',
            text: '✖️ A Journey Into House Music'
          },
          {
            title: 'Sinner',
            link: '/brands/sinner-tulum',
            image: 'https://imgix.cosmicjs.com/e0af8980-947c-11ee-8a8d-abf95e574482-image.png',
            text: '"Every Saint has a past, every sinner has a future"'
          },
          // {
          //   title: 'PIKNIK',
          //   link: '/brands/piknik-tulum',
          //   image: 'https://imgix.cosmicjs.com/128011f0-947d-11ee-8a8d-abf95e574482-image.png',
          //   text: 'Delicious musical Selection. Every Saturday.'
          // },
          {
            title: 'Summerians',
            link: '/brands/summerians-ibiza-tulum',
            image: 'https://imgix.cosmicjs.com/25e8f420-c865-11ee-9ce5-59949019255e-summeriansibiza.jpg',
            text: 'A musical & immersive journey through multisensory experiences by Kintar'
          },
          // {
          //   title: 'DPM ',
          //   link: '/brands/dpm-global-tulum',
          //   image: 'https://imgix.cosmicjs.com/25f944a0-99fe-11ee-bdf1-7fea048bb7c9-image.png',
          //   text: 'Things done one way, DE PUTA MADRE!'
          // },
        ].sort((x, y) => (x.title.localeCompare(y.title)));
      },
    },
  },
  created: function () {
  },

  components: {
    MiniBrandCard,
  },
};
</script>
